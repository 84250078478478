
import React, {useEffect } from "react";
import axios from "axios";
import parseJSON from '../utils/parseJSON'
import findNodeByContent from "./findNodebyContent";
import ENUM_DATA_MS_ROLE from "../constants/enum-ms-data-role";
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ReactDOM from "react-dom";
import cleanJSON from '../utils/cleanJSON'
import InitPlayer from './initPlayer'
const MappingData = ({}) => {
    useEffect(() => {

        window.addEventListener('scroll', () => {
            bindKeyFillData()
        })
        bindKeyFillData();

    }, [])

    const bindKeyFillData = () => {
        var script = document.querySelector('#scriptArrayBindData');
        if (script) {
            var data = window.getArray();
            if (data && data.length) {
                data.forEach(item => {
                    const {key, value, html, dataIndexConnected,pageSize,Related_news} = item || {};
                    var elementBindkey = document.querySelector(`[data-bind-key='${key}']`);
                    if(elementBindkey){
                        var bindkey = elementBindkey.getAttribute("data-bind-key");
                        if(pageSize){
                            elementBindkey.parentNode.setAttribute('page-size', pageSize)
                        }

                        var position = elementBindkey.getBoundingClientRect();
                        var hegihtWindow = window.innerHeight;
                        // var arrayBindKey = [];

                        if ((position.y > hegihtWindow) || elementBindkey.getAttribute('nogetData')) {
                            return;
                        }

                        elementBindkey.setAttribute('nogetData', true);
                        if (elementBindkey) {
                            var allitem = elementBindkey.querySelectorAll('[ms-role="item"]');

                            if (allitem && allitem.length) {
                                Array.from(allitem).forEach(t => {
                                    t.classList.add('item-loading');
                                })
                            }
                            let url = `/getDataMapping`;
                            var search = window.location.search;
                            var domain = window.location.host
                            var pathname = window.location.pathname

                           // if (pathname == '/mini-stories') url = `${pathname}${url}`
                            if(pathname && pathname != '/' && (domain =='m.afamily.vn' || domain =='afamily.vn' || domain == 'giadinh.suckhoedoisong.vn')){
                                url = `${window.location.pathname}${url}`;
                                if(domain == 'm.afamily.vn'){
                                    domain = 'afamily.vn'
                                }
                                url = `https://${window.location.host}/nen-tang-hanh-phuc/getDataMapping`;

                               // url = `${pathname.replace('.html','')}${url}`.replace("m." ,"")
                            }
                            // var url = `https://localhost:44381/getDataMapping`;
                            var zoneId;
                            if(Related_news){
                                var domEle = document.querySelector('[articleinzone]')
                                if(domEle){
                                    zoneId =  domEle.getAttribute('articleinzone')
                                }
                            }
                            var newDataArray = []
                            axios.get(url, {
                                params: cleanJSON({
                                    value,
                                    domain,
                                    param: search ? search.replace('?', '') : null,
                                    pathname:window.location.pathname,
                                    zoneId
                                })
                            }).then(res => {
                                const {data} = res || '';

                                console.log(data,'data')
                                let dataJson = parseJSON(data || '[]');
                                newDataArray = dataJson
                                if(dataJson && dataJson.length < pageSize){
                                    var parentId =    elementBindkey.parentNode.getAttribute('id');

                                    if(parentId){
                                        var findLoadmore = document.querySelector(`[for='${parentId}']`);
                                        if(findLoadmore){
                                            findLoadmore.remove()
                                        }
                                    }
                                }
                                if (dataJson && dataJson.length) {

                                    const parser = new DOMParser();
                                    const doc = parser.parseFromString(html, "text/html");
                                    let bodyNode = doc && doc.body && doc.body.childNodes && doc.body.childNodes[0];
                                    let htmlElement = bodyNode && bodyNode.ownerDocument && bodyNode.ownerDocument.documentElement;
                                    var blockItem = htmlElement.querySelector('[ms-role="item"]');
                                    let msRole = elementBindkey.getAttribute(ENUM_DATA_MS_ROLE.MS_ROLE);

                                    var news = dataJson;
                                    if (msRole == 'list') {
                                        elementBindkey.innerHTML = ''
                                        if (dataIndexConnected && dataIndexConnected > 0) {
                                            news = dataJson && dataJson.filter((t, index) => {

                                                if (dataIndexConnected) {
                                                    return index >= dataIndexConnected
                                                }
                                                return true
                                            })

                                        }
                                        news.forEach((t, index) => {
                                            if (news && news[index]) {
                                                var newHtml = blockItem.cloneNode(true);

                                                fillDataToItem(newHtml, news[index], bindkey).then(() => {
                                                    newHtml.setAttribute('newsId' , news[index].newsId )
                                                    newHtml.setAttribute('zoneId' , news[index].zoneId )
                                                    newHtml.classList.remove('item-loading');
                                                    elementBindkey.appendChild(newHtml);
                                                })
                                            }
                                        })
                                        // Array.from(allitem).forEach((t,index) =>{
                                        //     if(news && news[index]){
                                        //         var newHtml = blockItem.cloneNode(true);
                                        //         fillDataToItem(newHtml,news[index] , bindkey).then(() =>{
                                        //             t.replaceWith(newHtml);
                                        //             t.classList.remove('item-loading');
                                        //         })
                                        //     }
                                        // })
                                    } else {
                                        var newHtml = elementBindkey.cloneNode(true);

                                        const doc = parser.parseFromString(html, "text/html");
                                        let bodyNode = doc && doc.body && doc.body.childNodes && doc.body.childNodes[0];
                                        let htmlElement = bodyNode && bodyNode.ownerDocument && bodyNode.ownerDocument.documentElement && bodyNode.ownerDocument.documentElement.body;

                                        fillDataToItem(htmlElement, news[dataIndexConnected], bindkey).then(() => {
                                            elementBindkey.replaceWith(htmlElement);
                                            elementBindkey.classList.remove('item-loading');

                                        })
                                    }

                                } else {
                                    if (allitem && allitem.length) {
                                        Array.from(allitem).forEach(t => {
                                            t.remove()
                                        })
                                    }
                                }

                            }).catch(() => {
                                if (allitem && allitem.length) {
                                    Array.from(allitem).forEach(t => {
                                        t.classList.remove('item-loading');
                                    })
                                }
                            }).finally(() =>{
                                // if(pageSize){
                                //
                                // }
                                var userArray = [];
                                if(newDataArray && newDataArray.length){
                                    userArray = newDataArray && newDataArray.map(item =>{
                                        const {user,newsId} = item;
                                        return {user,newsId}
                                    })
                                }

                                if(window.loadDataSuceess && typeof window.loadDataSuceess == 'function'){
                                    window.loadDataSuceess({element:elementBindkey,author:userArray})
                                }else{
                                    setTimeout(() =>{
                                        if(window.loadDataSuceess && typeof window.loadDataSuceess == 'function'){
                                            window.loadDataSuceess({element:elementBindkey,author:userArray})
                                        }
                                    } , 1500)
                                }

                            })
                        }
                    }



                });
            }

        }
    }

    const getPropertyItem = (news) => {
        let arr = []
        for (const property in news) {
            arr.push(property)
        }

        return arr;
    }


    const buildLinkThumb = (host, path, width, height) => {
        let domain = host.trimRight('/');
        let relativePath = path.trimLeft("/");

        if (height)
            return `${domain}/auto_crop/${width}_${height}/${relativePath}`;

        if (width)
            return `${domain}thumb_w/${width}/${relativePath}`;

        return `${domain}/${relativePath}`;
    }

    const mapData = (element, data, property) => {
        if (!element || !element.node) return;
        let localName = element && element.node.localName;


        if(property == 'avatar3'){
            // console.log(element,property,'element,property OK')
            ReactDOM.render(<InitPlayer data={data} />, element.node);
        }else if (element.type == 'background-image') {
            let regex = /url([^"]+)/i
            let style = element.node.getAttribute('style');
            let string = 'url(' + "'" + data + "'" + ')'
            element.node.setAttribute('style', style.replace(regex, string))
            element.node.setAttribute(ENUM_DATA_MS_ROLE.MS_PROPERTY, property);
        } else if (localName === 'a' && element.type == 'href') {

            element.node.setAttribute('href', data);
            // element.node.setAttribute('title', data);
            element.node.setAttribute(ENUM_DATA_MS_ROLE.MS_PROPERTY_LINK, property);
        } else if (localName === 'img') {

            let [g1, domain, relativePath] = data.split(/((?:http:|https:)?\/\/[^\/]+\/)(?:zoom\/\d+_\d+\/|thumb_w\/\d+\/|crop\/\d+,\d+_\d+,\d+\/)?/ig);
            domain = domain.trimRight("/");
            let src = buildLinkThumb(domain, relativePath, 650)

            element.node.setAttribute('src', src);
            element.node.setAttribute('alt', src);
            element.node.setAttribute(ENUM_DATA_MS_ROLE.MS_PROPERTY, property);
        } else if (element.type == 'body') {
            element.node.innerHTML = data
            element.node.setAttribute(ENUM_DATA_MS_ROLE.MS_PROPERTY, property);
        } else {
           // nếu có chứa time-ago

            if (element.node.classList.contains("time-ago")) {
                const frenchStrings= {
                    prefixAgo: '',
                    prefixFromNow: null,
                    suffixAgo: null,
                    suffixFromNow: 'trước',
                    seconds: 'chưa đến 1 phút',
                    minute: 'khoảng 1 phút',
                    minutes: '%d phút',
                    hour: 'khoảng 1 tiếng',
                    hours: 'khoảng %d tiếng',
                    day: '1 ngày',
                    days: '%d ngày',
                    month: 'khoảng 1 tháng',
                    months: '%d tháng',
                    year: 'khoảng 1 năm',
                    years: '%d năm',
                    wordSeparator: ' ',
                }

                const formatter = buildFormatter(frenchStrings)
                ReactDOM.render(<TimeAgo date={data} formatter={formatter} />, element.node);
            } else {
                element.node.innerText = data;
            }

            element.node.setAttribute(ENUM_DATA_MS_ROLE.MS_PROPERTY, property);
        }

    }


    const fillDataToItem = (element, data, key) => {

        return new Promise((resolve, reject) => {
            let propertys = getPropertyItem(data);
            var title = data['title'];
            if (propertys && propertys.length) {
                for (var i = 0; i < propertys.length; i++) {
                    let property = propertys[i];

                    if (property == 'url') {
                        let pattern = '${' + key + '.' + property + '}';

                        let findNode = findNodeByContent(pattern, element);
                        var node = findNode && findNode[0] && findNode[0].node;

                        if (node) {
                            node.setAttribute('title', title)
                        }
                        // node.setAttribute('title' , title)
                        if (findNode && findNode.length) {
                            debugger
                            findNode.forEach(node =>{
                                mapData(node, data[property], property);
                            })

                        }
                    }
                    let pattern = '${' + key + '.' + property + '}';
                    let findNode = findNodeByContent(pattern, element);

                    if (findNode && findNode.length) {
                        if (property == 'url') {
                            var node = findNode && findNode[0] && findNode[0].node;
                            if (node) {
                                node.setAttribute('title', title)
                            }
                        }
                        if (findNode && findNode.length) {
                            findNode.forEach(node =>{
                                mapData(node, data[property], property);
                            })
                        }
                       // mapData(findNode && findNode[0], data[property], property);
                    }
                }
            }
            resolve(true)
        })

    }


    return null
};
export default MappingData;
